<template>
  <layout width="540">
    <template slot="header">
      Edit object group
    </template>

    <template>
      <v-form>
        <v-text-field v-model.trim="state.name" label="Name" />
        <edit-rights-group-fields
          v-model="accessRights"
          :rights-editor="rightsEditor"
          :rights-user="rightsUser"
          :rights-reader="rightsReader"
        />
        <form-subheader title="Description" />
        <v-textarea v-model="state.description" no-resize rows="1" auto-grow />
        <v-btn
          color="primary"
          class="mb-4 text-uppercase"
          style="width: 100%"
          outlined
          @click.stop="copyUUID"
        >
          Copy UUID
        </v-btn>
        <v-btn
          color="error"
          class="text-uppercase"
          style="width: 100%"
          depressed
          @click.stop="deleteGroup"
        >
          Delete group
        </v-btn>
      </v-form>
    </template>
    <template slot="footer">
      <v-spacer />
      <v-btn text color="text-primary" @click.stop="$emit('close')">
        Cancel
      </v-btn>
      <v-btn
        text
        color="primary"
        depressed
        :loading="loading"
        @click.stop="updateGroup"
      >
        Save
      </v-btn>
    </template>
  </layout>
</template>

<script>
import Layout from '@/components/popup/PopupLayoutDefault';
import { onMounted, ref } from '@vue/composition-api';
import { usePromise } from 'vue-composable';
import ObjectGroupService from '../api/object-group.js';
import { usePopup } from '@/compositions/popup';
import { useCopy } from '@/compositions/copy.js';
import { useSnackBar } from '@/compositions/snackBar.js';
import EditRightsGroupFields from '@/modules/access/ui/EditRightsGroupFields.vue';
import Vue from 'vue';
import { useAccessRights } from '@/modules/access/compositions/access-rights';

export default Vue.extend({
  name: 'ObjectGroupEdit',
  components: {
    Layout,
    EditRightsGroupFields
  },
  props: {
    id: {
      type: String,
      default: ''
    }
  },
  setup(props, { emit, root }) {
    const state = ref({
      id: '',
      name: '',
      descriprion: ''
    });

    const accessRights = ref({
      editorgroup: '',
      usergroup: '',
      readergroup: ''
    });

    const {
      load,
      rightsEditor,
      rightsUser,
      rightsReader,
      save
    } = useAccessRights();

    const updateGroup = async () => {
      await ObjectGroupService.update(state.value);
      await save(state.value.id, accessRights.value);
      emit('close');
    };

    const popup = usePopup();
    const snackBar = useSnackBar();
    const deleteGroup = async () => {
      popup.openConfirm({
        component: () => import('@/components/popup/PopupConfirmAction.vue'),
        props: {
          title: 'Delete object group?',
          onConfirm: async () => {
            await ObjectGroupService.delete(state.value.id);
            snackBar.show('Deleted sucessfully');
            await root.$router.push({ name: 'objects' }).catch(() => {});
          }
        },
        listeners: {
          close: () => emit('close')
        }
      });
    };

    const { copyWithAlert } = useCopy();

    const copyUUID = () => {
      copyWithAlert(state.value.id);
    };

    const { loading, exec } = usePromise(updateGroup, true);

    onMounted(async () => {
      await load();
      const {
        id,
        name,
        description,
        editorgroup,
        usergroup,
        readergroup
      } = await ObjectGroupService.fetchObjectGroup(props.id);

      state.value = {
        id,
        name,
        description
      };

      accessRights.value = {
        editorgroup,
        usergroup,
        readergroup
      };
    });

    return {
      state,
      exec,
      loading,
      deleteGroup,
      updateGroup,
      copyUUID,
      rightsEditor,
      rightsUser,
      rightsReader,
      accessRights
    };
  }
});
</script>
